import axios from 'axios'
import store from '@/store'

export default {
  methods: {
    async getLocationNotes(locationId, page, itemsPerPage) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/location/${locationId}`,
        {
          params: {
            page: page,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getCompanyNotes(companyId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/company/${companyId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getInvoiceNotes(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/invoice/${invoiceId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getProfileNotes(profileId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/customer/${profileId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getOptionNotes(optionId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/option/${optionId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationNotes(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/reservation/${reservationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getVoucherNotes(voucherId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/voucher/${voucherId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelNotes(channelId, type, itemId, page, itemsPerPage) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/notes`,
        {
          params: {
            type: type,
            itemId: itemId,
            page: page,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelLocationNotes(channelId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/channel/${channelId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getCartOptionNotes(cartoptionId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/cartoption/${cartoptionId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationOptionNotes(reservationoptionId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/reservationoption/${reservationoptionId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationSpaceNotes(reservationspaceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/reservationspace/${reservationspaceId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getProposalRequestNotes(requestId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/proposal/request/${requestId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteNote(noteId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/${noteId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createNote(note) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note`,
        JSON.stringify(note),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateNote(note) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/${note.Id}`,
        JSON.stringify(note),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async shareNote(noteId, locationId, profileIds) {
      let criteria = {
        NoteId: noteId,
        LocationId: locationId,
        ProfileIds: profileIds
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/${noteId}/share`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async setToDoStatus(note) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/note/${note.Id}/done`,
        JSON.stringify(note),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
