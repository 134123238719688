<template>
  <div>
    <ModalCreateNote
      v-if="showCreateNote"
      :showModal="showCreateNote"
      :typeId="typeId"
      :itemId="itemId"
      :linkTypeId="linkTypeId"
      :linkItemId="linkItemId"
      :locationId="locationId"
      :viewType="viewType"
      :onClickCancel="hideModal"
      @noteCreated="noteCreated"
      :workEnvironment="workEnvironment"
      :environmentId="environmentId"
    />

    <ModalEditNote
      v-if="showEditNote"
      :showModal="showEditNote"
      :note="selectedNote"
      :onClickCancel="hideModal"
      @noteEdited="noteEdited"
    />

    <ModalShareNote
      v-if="showShareNote"
      :showModal="showShareNote"
      :note="selectedNote"
      :onClickCancel="hideModal"
      @noteShared="noteShared"
      :workEnvironment="workEnvironment"
      :environmentId="environmentId"
    />

    <ModalDeleteNote
      v-if="showDeleteNote"
      :showModal="showDeleteNote"
      :note="selectedNote"
      :onClickCancel="hideModal"
      @noteDeleted="noteDeleted"
    />

    <nav>
      <div
        v-if="viewType === 'small' && showAddLink"
        class="is-size-7 is-hidden-print"
        :class="{
          'has-text-left': showAddLinkLeft,
          'has-text-right': !showAddLinkLeft,
        }"
      >
        <a @click="setShowCreateNote">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
          <span>Add note</span>
        </a>
      </div>

      <div
        v-if="viewType === 'normal'"
        class="actionBar has-background-white-ter has-padding has-margin-bottom is-hidden-print"
      >
        <div class="level is-small is-hidden-print">
          <div class="level-left"></div>
          <div class="level-right">
            <a @click="setShowCreateNote">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Add note</span>
            </a>
          </div>
        </div>
      </div>
    </nav>

    <transition-group name="notes" tag="div">
      <div
        v-for="note in mNotes"
        :key="'note' + note.Id + note.Subject + note.Body"
      >
        <div>
          <NoteItem
            :note="note"
            @editNote="setShowEditNote"
            @shareNote="setShowShareNote"
            @deleteNote="setShowDeleteNote"
            :key="'note' + note.Id"
            :showItemName="showItemName"
            :showLink="showLink"
            :showShareLink="showShareLink"
            :showEditLink="showEditLink"
            :showNoteId="showNoteId"
            :showDeleteLink="showDeleteLink"
          />
        </div>
      </div>
    </transition-group>

    <Message
      v-if="mNotes.length === 0 && showNoNotesMessage"
      :message="$t('Components.Notes.Notes.Message_NoNotes')"
      :size="viewType === 'small' ? 'is-small' : ''"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import noteProvider from '@/providers/note'

const ModalCreateNote = () => import('@/components/Notes/ModalCreateNote')
const ModalEditNote = () => import('@/components/Notes/ModalEditNote')
const ModalShareNote = () => import('@/components/Notes/ModalShareNote')
const ModalDeleteNote = () => import('@/components/Notes/ModalDeleteNote')
const NoteItem = () => import('@/components/Notes/NoteItem')
const Message = () => import('@/components/UI/Message')

export default {
  components: {
    ModalCreateNote,
    ModalEditNote,
    ModalShareNote,
    ModalDeleteNote,
    NoteItem,
    Message,
  },

  props: {
    getFresh: {
      default: true,
      type: Boolean,
    },

    header: {
      default: '',
      type: String,
    },

    locationId: {
      default: 0,
      type: Number,
    },

    itemId: {
      default: 0,
      type: Number,
    },

    notes: {
      default: function () {
        return []
      },
      type: Array,
    },

    typeId: {
      default: 'None',
      type: String,
    },

    linkItemId: {
      default: 0,
      type: Number,
    },

    linkTypeId: {
      default: 'None',
      type: String,
    },

    showAddLink: {
      type: Boolean,
      default: true,
    },

    showAddLinkLeft: {
      type: Boolean,
      default: false,
    },

    showNoNotesMessage: {
      type: Boolean,
      default: true,
    },

    showShareLink: {
      type: Boolean,
      default: true,
    },

    showEditLink: {
      type: Boolean,
      default: true,
    },

    showDeleteLink: {
      type: Boolean,
      default: true,
    },

    showNoteId: {
      type: Boolean,
      default: false,
    },

    viewType: {
      default: 'normal',
      type: String,
    },

    workEnvironment: {
      type: String,
      default: 'Manage',
    },

    environmentId: {
      type: Number,
      default: 0,
    },

    showItemName: {
      type: Boolean,
      default: false,
    },

    showLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isSearching: false,
      mNotes: this.notes,
      showCreateNote: false,
      showEditNote: false,
      showShareNote: false,
      showDeleteNote: false,
      showNoteInput: false,
      showNotes: true,
      selectedNote: null,
      timestamp: new Date().getTime(),
    }
  },

  watch: {
    notes: {
      immediate: true,
      handler: function (val) {
        this.mNotes = val
      },
    },
  },

  created() {
    if (this.getFresh) {
      this.getNotes()
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profileStore/getProfile',
    }),
  },

  methods: {
    getNotes() {
      switch (this.typeId) {
        case 'Location':
          this.getChannelLocationNotes()
          break
        case 'Company':
          this.getCompanyNotes()
          break
        case 'Invoice':
          this.getInvoiceNotes()
          break
        case 'Option':
          this.getOptionNotes()
          break
        case 'Customer':
          this.getProfileNotes()
          break
        case 'Quote':
          this.getQuoteNotes()
          break
        case 'Reservation':
          this.getReservationNotes()
          break
        case 'Voucher':
          this.getVoucherNotes()
          break
        case 'ReservationOption':
          this.getReservationOptionNotes()
          break
        case 'ProposalRequest':
          this.getProposalRequestNotes()
          break
        case 'CartOption':
          this.getCartOptionNotes()
          break
        case 'ReservationSpace':
          this.getReservationSpaceNotes()
          break
      }
    },

    getChannelLocationNotes() {
      let self = this
      noteProvider.methods
        .getChannelLocationNotes(self.channelId, self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getCompanyNotes() {
      let self = this
      noteProvider.methods
        .getCompanyNotes(self.itemId, self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getInvoiceNotes() {
      let self = this
      noteProvider.methods
        .getInvoiceNotes(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getProfileNotes() {
      let self = this
      noteProvider.methods
        .getProfileNotes(self.itemId, self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getOptionNotes() {
      let self = this
      noteProvider.methods
        .getOptionNotes(self.itemId, self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getReservationNotes() {
      let self = this
      noteProvider.methods
        .getReservationNotes(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getVoucherNotes() {
      let self = this
      noteProvider.methods
        .getVoucherNotes(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getCartOptionNotes() {
      let self = this
      noteProvider.methods
        .getCartOptionNotes(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getReservationOptionNotes() {
      let self = this
      noteProvider.methods
        .getReservationOptionNotes(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getReservationSpaceNotes() {
      let self = this
      noteProvider.methods
        .getReservationSpaceNotes(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    getProposalRequestNotes() {
      let self = this
      noteProvider.methods
        .getProposalRequestNotes(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mNotes = response.data
          }
        })
        .catch((error) => {})
    },

    noteDeleted(deletedNote) {
      let self = this
      let noteIndex = self.mNotes.findIndex((n) => n.Id === deletedNote.Id)
      if (noteIndex > -1) {
        self.mNotes.splice(noteIndex, 1)
        self.$emit('noteDeleted', deletedNote)
      }
    },

    noteCreated(newNote) {
      let self = this
      self.mNotes.unshift(newNote)
      self.$emit('noteAdded', newNote)
      self.$emit('noteCreated', newNote)
    },

    noteShared(sharedNote) {
      let noteIndex = this.mNotes.findIndex((n) => n.Id === sharedNote.Id)

      if (noteIndex > -1) {
        Vue.set(this.mNotes, noteIndex, sharedNote)
        this.$emit('noteShared', sharedNote)
        this.timestamp = new Date().getTime()
      }
    },

    noteEdited(editedNote) {
      let self = this

      let noteIndex = self.mNotes.findIndex((n) => n.Id === editedNote.Id)
      if (noteIndex > -1) {
        self.$emit('noteEdited', editedNote)
        Vue.set(self.mNotes, noteIndex, editedNote)

        //self.timestamp = new Date().getTime()
      }
    },

    setShowCreateNote() {
      this.showCreateNote = true
    },

    setShowEditNote(note) {
      this.selectedNote = note
      this.showEditNote = true
    },

    setShowShareNote(note) {
      this.selectedNote = note
      this.showShareNote = true
    },

    setShowDeleteNote(note) {
      this.selectedNote = note
      this.showDeleteNote = true
    },

    hideModal() {
      this.showCreateNote = false
      this.showEditNote = false
      this.showShareNote = false
      this.showDeleteNote = false
    },
  },
}
</script>
